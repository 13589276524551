<template>
  <MainPage />
</template>

<script setup lang="ts">
import MainPage from '~/features/MainPage/ui/MainPage.vue'

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://medflex.ru'
    }
  ]
})

const { statistics } = useStatisticsData()

useSeoMeta({
  description: `${statistics.value.lpusCount} - столько частных клиник и стоматологий используют сервис МедФлекс для безопасного обмена данными с онлайн-сервисами и страховыми компаниями, а также для организации онлайн-записи пациентов`
})
</script>
